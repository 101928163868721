<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">APPLIANCES/GADGET LOANS HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Application #
            </th>
            <th class="text-center text-uppercase">
              Terms
            </th>
            <th class="text-center text-uppercase">
              Date
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Interest
            </th>
            <th class="text-center text-uppercase">
              Total
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase">
              SOA
            </th>
            <th class="text-center text-uppercase">
              Sched
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              For Assume
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>{{ item.glaf_no }}</td>
            <td class="text-center">
              {{ item.terms }}
            </td>
            <td class="text-center">
              {{ item.date_accomplished }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.interest) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.total) }}
            </td>
            <td class="text-center">
              <div v-if="item.paid===2">
                <v-chip color="success" dark>ASSUMED</v-chip>
              </div>
              <div v-else-if="item.proceessing_fee===0">
                <v-chip color="error" dark>PENDING</v-chip>
              </div>
              <div v-else-if="item.paid === 1">
                <v-chip color="success" dark>PAID</v-chip>
              </div>
              <div v-else>
                <v-chip color="warning" dark>ACTIVE</v-chip>
              </div>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="soa_data(item)"
              >
                {{icons.mdiFileEye}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                @click="print_loan_schedule(item)"
              >
                {{icons.mdiAccountClock}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="error"
                v-if="item.paid===0 && position==='ADMIN'"
                @click="assume_loan(item)"
              >
                {{icons.mdiAccountCash}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="soa_show" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">APPLIANCES/GADGET LOANS STATEMENT OF ACCOUNT</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="8"
              class="pt-6"
            >
              <v-text-field label="Type of Appliances/Gadget"
                            v-model="loans_data.type_of_gadget"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pt-6"
            >
              <v-text-field label="Specify"
                            v-model="loans_data.type_of_loan_others"
                            v-if="loans_data.type_of_loan === 'Other'"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Loan Amount"
                            v-model="loans_data.amount"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Mode of Loan"
                            v-model="loans_data.mode_of_loan"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Terms of Loan"
                            v-model="loans_data.terms"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Due Amount"
                            readonly
                            v-model="amount_due"
                            outlined
                            dense
                            background-color="info"
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Interest"
                            readonly
                            v-model="loans_data.interest"
                            outlined
                            dense
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Total"
                            readonly
                            v-model="total_loan"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Balance"
                            background-color="warning"
                            readonly
                            v-model="balance"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
              v-if="loans_data.is_assumed===1"
            >
              <v-text-field label="Remarks"
                            background-color="warning"
                            readonly
                            v-model="assume_by"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">APPLIANCES/GADGET LOANS PAYMENT</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <h4 class="font-weight-light">Print SOA:</h4>
          <v-icon
            class="mr-2"
            @click="print_soa()"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                OR Date
              </th>
              <th class="text-center text-uppercase">
                OR No
              </th>
              <th class="text-center text-uppercase">
                Amount
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in loans_payment"
              :key="item.id"
            >
              <td>{{ item.or_date }}</td>
              <td class="text-center">
                {{ item.or_no }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
    <assume-gadget-loans-application
      :key="this.key"
      v-on:dialog_value="on_assume_dialog_close"
      :dialog_show="assumme_show"
      :loans_data="loans_data"
      :loans_information="loans_information"
      :prev_balance="prev_balance"
    ></assume-gadget-loans-application>
  </div>
</template>

<script>
  import {mdiPrinter, mdiFileEye, mdiAccountCash, mdiAccountClock} from '@mdi/js'
  import {mapGetters} from "vuex";
  import moment from 'moment'
  import AssumeGadgetLoansApplication from '../member_components/loans/AssumeGadgetLoansApplication'

  export default {
    props: {
      data: Array,
      member_data: Object,
    },
    components: {
      AssumeGadgetLoansApplication
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/logos/main-logo.png'),
        icons: {
          mdiPrinter,
          mdiFileEye,
          mdiAccountCash,
          mdiAccountClock,
        },
      }
    },
    data() {
      return {
        key:0,
        assumme_show: false,
        soa_show: false,
        loans_data: {},
        loans_information: [],
        loans_payment: [],
        balance: 0,
        prev_balance: 0,

        total_loan: 0,
        amount_due: 0,

        assume_by: '',
        data_temp: {}
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_address', 'branch_contact_no', 'position']),
      ...mapGetters('system_data', ['company_logo']),
    },
    watch: {
      soa_show: function () {
        if (!this.soa_show) {
          this.loans_data = {}
          this.loans_payment = []
          this.data_temp = {}
          this.amount_due = 0
          this.balance = 0
          this.assume_by = ''
        }
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      soa_data(data) {
        this.soa_show = true
        this.loans_data = data
        this.loans_payment = data.loans_payment
        this.data_temp = data
        var total_payment = 0;
        if (this.loans_payment.length > 0) {
          this.loans_payment.forEach(function (item) {
              total_payment += parseFloat(item.amount)
            }
          )
        }
        this.assume_by = data.assume_by
        this.balance = this.formatPrice(parseFloat(data.total) - parseFloat(total_payment));
        if (data.is_assumed === 1 && data.is_continue === 0) {
          this.balance = 0
        }
        this.total_loan = this.formatPrice(this.loans_data.total)
        this.amount_due = this.formatPrice(this.loans_data.amount_due)
      },
      print_soa() {
        var imgData = this.company_logo
        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Or Date', alignment: 'left', style: 'label'},
            {text: 'OR #', alignment: 'left', style: 'label'},
            {text: 'Amount', alignment: 'left', style: 'label'},
          ]
        )
        if (this.loans_payment.length > 0) {
          this.loans_payment.forEach(function (item) {
              accounts_array.push(
                [
                  {text: item.or_date, alignment: 'left'},
                  {text: item.or_no.substring(0, item.or_no.indexOf('-')), alignment: 'left'},
                  {text: item.amount, alignment: 'left'},
                ]
              )
            }
          )
        } else {
          accounts_array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.data_temp.branch.address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.data_temp.branch.contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'APPLIANCES/GADGET LOAN STATEMENT OF ACCOUNT (SOA)', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              columns: [
                {text: 'Branch: ' + this.data_temp.branch.branch_code, style: 'main_info'},
                {
                  text: 'Date Accomplished: ' + this.data_temp.date_accomplished,
                  style: 'main_info'
                },
                {text: 'GLAF #: ' + this.data_temp.laf_no, style: 'main_info'},
              ]
            },
            '_________________________________________________________________________________________________',
            'MEMBERS PERSONAL INFORMATION',
            ' ',
            {
              columns: [
                {
                  text: 'Applicant Name: ' + this.member_data.last_name + ', ' + this.member_data.first_name + ' ' + this.member_data.middle_name,
                  style: 'main_info'
                },
                {
                  text: 'Contact #: ' + this.data_temp.loans_information[0].contact_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Present Address: ' + this.data_temp.loans_information[0].present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'CO MAKER INFORMATION',
            ' ',
            {
              columns: [
                {text: 'CO - MAKER NAME', style: 'main_info'},
                {text: 'CO - MAKER CONTACT #', style: 'main_info'},
                {text: 'CO - MAKER ADDRESS', style: 'main_info'},
              ]
            },
            {
              columns: [
                {
                  text: this.data_temp.loans_information[0].co_maker.last_name + ', ' + this.data_temp.loans_information[0].co_maker.first_name + ' ' + this.data_temp.loans_information[0].co_maker.middle_name,
                  style: 'main_info'
                },
                {text: this.data_temp.loans_information[0].co_maker.contact_no, style: 'main_info'},
                {
                  text: this.data_temp.loans_information[0].co_maker.present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'LOAN DATA',
            ' ',
            {
              columns: [
                {text: 'Type of Loan', style: 'main_info'},
                {text: this.data_temp.type_of_gadget, style: 'main_info'},
                {text: '', style: 'main_info'},
                {text: '', style: 'main_info'},
                {text: '', style: 'main_info'},
                {text: '', style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: this.data_temp.mode_of_loan, style: 'main_info'},
                {text: this.data_temp.terms, style: 'main_info'},
                {text: '|', style: 'main_info'},
                {text: this.data_temp.amount + '     +', style: 'main_info'},
                {text: this.data_temp.interest + '     +', style: 'main_info'},
                {text: this.data_temp.total, style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: 'Mode', style: 'sub_info'},
                {text: 'Terms', style: 'sub_info'},
                {text: '|', style: 'main_info'},
                {text: 'Loan Amount', style: 'sub_info'},
                {text: 'Interest', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',
            {
              text: 'BALANCE: ' + this.balance,
              alignment: 'center',
              style: 'main_info',
              color: 'red'
            },
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [250, 130, 130],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
      print_loan_schedule(data) {
        var imgData = this.company_logo
        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Due Date', alignment: 'left', style: 'label'},
            {text: 'Loan', alignment: 'left', style: 'label'},
            {text: 'Interest', alignment: 'left', style: 'label'},
            {text: 'Due Amount', alignment: 'left', style: 'label'},
            {text: 'Balance', alignment: 'left', style: 'label'}
          ]
        )
        var maximum_due = 0
        var partial_balance = data.total
        var loan_savings = 0
        var partial_loan_due_amount = 0
        var due_date = data.date_accomplished
        var amount_due = data.amount_due
        switch (data.mode_of_loan) {
          case "Weekly":
            maximum_due = parseFloat(data.terms) * 4
            loan_savings = parseFloat(data.interest) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - loan_savings)
            for (var i = 1; i <= maximum_due; i++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(7, 'days').format('MMMM D, YYYY')
              partial_balance = partial_balance - data.amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(partial_loan_due_amount), alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          case "15/30":
            maximum_due = parseFloat(data.terms) * 2
            loan_savings = parseFloat(data.interest) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - loan_savings)
            for (var x = 1; x <= maximum_due; x++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(15, 'days').format('MMMM D, YYYY')
              partial_balance = partial_balance - data.amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(partial_loan_due_amount), alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          case "Monthly":
            maximum_due = parseFloat(data.terms)
            loan_savings = parseFloat(data.interest) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - loan_savings)
            for (var j = 1; j <= maximum_due; j++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(1, 'months').format('MMMM D, YYYY')
              partial_balance = partial_balance - data.amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(partial_loan_due_amount), alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          default:
            break;
        }

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'CONTRACT TO SELL', style: 'title'},
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              columns: [
                {
                  text: [{
                    text: 'This is to certify that ',
                    margin: [0, 2, 0, 2],
                    fontSize: 10,
                  }, {
                    text: this.member_data.last_name + ', ' + this.member_data.first_name + ' ' + this.member_data.middle_name,
                    bold: true,
                  },
                    {
                      text: ' of legal age ',
                      margin: [0, 2, 0, 2],
                      fontSize: 10,
                    },
                    {
                      text: this.member_data.age,
                      bold: true,
                    }
                    , {
                      text: ', residence of ',
                      margin: [0, 2, 0, 2],
                      fontSize: 10,
                    }, {
                      text: data.loans_information[0].present_address,
                      bold: true,
                    }, {
                      text: ' and a bonafide employee of Goodlife Damayan Insurance Agency Co., where entitled to aquire a laptop loan at Goodlife Savings and Credit Cooperative with the following terms and conditions;',
                      margin: [0, 2, 0, 2],
                      fontSize: 10,
                    },]
                },
              ]
            },
            {
              columns: [
                {
                  text: '\u200B\t \u200B\t1. 2.5% Monthly interest rate\n' +
                    '\u200B\t \u200B\t2. Payable for 6 months only\n' +
                    '\u200B\t \u200B\t3. While loan still persist the said gadget loan is still under property of Goodlife Savings and Credit Cooperative unless it is fully paid.\n' +
                    '\u200B\t \u200B\t4. Salary Deduction every 5th and 20th of the month\n' +
                    '\u200B\t \u200B\t5. Failure to pay for consecutive 3 months the gadget will be return to the custody of Goodlife Savings and Credit Cooperative.\n',
                  style: 'main_info'
                },
              ],
            },
            {
              columns: [
                {
                  text: 'NOTE: If said gadget/appliances is not running in good condition when return whatever the cost of repair will be shoulder by the BUYER.',
                  bold: true,
                  color: 'red',
                  margin: [0, 2, 0, 2],
                  fontSize: 10,
                },
              ],
            },
            '_________________________________________________________________________________________________',
            'LOAN DATA',
            {
              columns: [
                {
                  text: 'Serial #: ' + data.loans_information[0].gadget_serial_no,
                  style: 'main_info'
                },
                {
                  text: 'Brand: ' + data.loans_information[0].gadget_brand,
                  style: 'main_info'
                },
                {
                  text: 'Model: ' + data.loans_information[0].gadget_serial_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {text: data.mode_of_loan, style: 'main_info'},
                {text: data.terms + ' month/s', style: 'main_info'},
                {text: '|', style: 'main_info'},
                {
                  text: this.formatPrice(data.amount) + '     +',
                  style: 'main_info'
                },
                {
                  text: this.formatPrice(data.interest) + '     =',
                  style: 'main_info'
                },
                {text: this.formatPrice(data.total), style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: 'Mode', style: 'sub_info'},
                {text: 'Terms', style: 'sub_info'},
                {text: '|', style: 'main_info'},
                {text: 'Loan Amount', style: 'sub_info'},
                {text: 'Interest', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [130, 90, 90, 90, 90],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              columns: [
                {
                  text: 'NOTE: The Gadget/Appliances are brand new and in good condition upon released, if sealed is broken the IT department is having testing run to insure that the item is good.',
                  bold: true,
                  color: 'red',
                  margin: [0, 2, 0, 2],
                  fontSize: 10,
                },
              ],
            },
            ' ',
            {
              columns: [
                {text: 'Prepared by:', style: 'main_info'},
                {text: 'Approved by:', style: 'main_info'},
                {text: 'Conforme:', style: 'main_info'},
              ]
            },
            ' ',
            {
              columns: [
                {text: '_________________________________', style: 'main_info'},
                {text: '_________________________________', style: 'main_info'},
                {text: '_________________________________', style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: 'COOP General Manager', style: 'main_info'},
                {text: 'COOP (Chairman/Vice/Board)', style: 'main_info'},
                {text: 'Buyer', style: 'main_info'},
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
      assume_loan(data) {
        this.loans_payment = data.loans_payment
        var total_payment = 0;
        if (this.loans_payment.length > 0) {
          this.loans_payment.forEach(function (item) {
              total_payment += parseFloat(item.amount)
            }
          )
          this.prev_balance = parseFloat(data.total) - parseFloat(total_payment);
        }
        this.loans_data = data
        this.loans_information = data.loans_information
        this.assumme_show = true
      },
      on_assume_dialog_close() {
        this.assumme_show = false
        this.loans_data = {}
        this.loans_information = []
        this.loans_payment = []
        location.reload()
      }
    },
  }
</script>
