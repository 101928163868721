<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOAN APPLICATION RECONSTRUCT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"

            >
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_accomplished"
                label="Date Accomplished"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                @keyup="calculation_of_loan"
                @change="calculation_of_loan"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="laf_no"
                label="LAF No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
              dense
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">Loan Data</h4></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-6"
                  >
                    <v-text-field label="Type of Loan"
                                  :rules="rules.default_max_45_character_and_no_empty_rule"
                                  v-model="type_of_loan"
                                  outlined
                                  dense
                                  readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-6"
                  >
                    <v-text-field label="Specify"
                                  :rules="rules.default_max_45_character_and_no_empty_rule"
                                  v-model="type_of_loan_others"
                                  v-if="type_of_loan === 'Other'"
                                  outlined
                                  dense
                                  readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  ></v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Loan Amount"
                                  v-model="loan_amount"
                                  @keyup="calculation_of_loan"
                                  @change="calculation_of_loan"
                                  outlined
                                  dense
                                  readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="['Weekly', '15/30', 'Monthly']"
                      label="Mode of Loan"
                      required
                      :rules="rules.combobox_rule"
                      v-model="mode_of_loan"
                      @change="calculation_of_loan"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      :items="['1 month/s', '2 month/s', '3 month/s', '4 month/s', '5 month/s', '6 month/s', '7 month/s', '8 month/s', '9 month/s', '10 month/s', '11 month/s', '12 month/s', '13 month/s', '14 month/s', '15 month/s', '16 month/s', '17 month/s', '18 month/s', '19 month/s', '20 month/s', '21 month/s', '22 month/s', '23 month/s', '24 month/s'
                                                            ,'25 month/s','26 month/s','27 month/s','28 month/s','29 month/s','30 month/s','31 month/s','32 month/s','33 month/s','34 month/s','35 month/s','36 month/s']"
                      label="Terms of Loan"
                      required
                      :rules="rules.combobox_rule"
                      v-model="terms"
                      @change="calculation_of_loan"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Due Amount"
                                  readonly
                                  v-model="due_amount"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Interest"
                                  readonly
                                  v-model="interest"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Loan Savings"
                                  readonly
                                  v-model="loan_savings"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Total"
                                  readonly
                                  v-model="total"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-if="Object.keys(this.value).length===0"
                      :items="ci_items"
                      item-value="id"
                      item-text="name"
                      label="CI By"
                      required
                      :rules="rules.combobox_rule"
                      v-model="ci_id"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Loan Insurance"
                                  readonly
                                  v-model="loan_insurance"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Loan Processing Fee"
                                  readonly
                                  v-model="loan_processing_fee"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Mortuary Insurance"
                                  readonly
                                  v-model="mortuary_insurance"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Foundation"
                                  readonly
                                  v-model="foundation"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Share Capital"
                                  type="number"
                                  min="1"
                                  :rules="rules.default_max_45_character_and_no_empty_rule"
                                  v-model="share_capital"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Regular Savings"
                                  type="number"
                                  min="1"
                                  :rules="rules.default_max_45_character_and_no_empty_rule"
                                  v-model="regular_savings"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="is_with_signature"
                label="With Signature"
                class="mt-0"
                hide-details
              ></v-checkbox>
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      value: Object,
      member_info: Object,
      prev_balance: Number
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.loan_counter_data()
    },
    data() {
      return {
        loan_insurance: '0',
        loan_insurance_comm: '0',
        loan_insurance_addtnl: '0',
        loan_processing_fee: '0',
        mortuary_insurance: '250',
        share_capital: '0',
        regular_savings: '0',
        foundation: '100',

        is_deminishing: false,
        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,
        croppieImage_bus: '',
        cropped_bus: this.avatarImg,
        croppieImage_home: '',
        cropped_home: this.avatarImg,

        search: '',
        search_member: '',
        search_items: [],

        search_co: '',
        search_member_co: '',
        search_items_co: [],
        interest_per_month: [],
        premium_per_month: [],

        collectors_items: [],
        collector_id: 0,

        date_accomplished: '',
        laf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        height: '',
        weight: '',
        tin_no: '',
        sss_no: '',
        due_date: '',
        type_of_id: '',
        id_no: '',
        civil_status: '',
        no_of_dependents: '',
        gender: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        religion: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        type_of_collateral: '',
        specify_collateral: '',
        is_with_signature: true,

        type_of_loan_others: '',
        due_amount: '',
        interest: '',
        mode_of_loan: '',
        terms: '',
        interestt: 0,
        due_interest: 0,
        loan_savings: '',
        loan_savingss: '',
        total: '',
        totall: '',
        ci_items: [],
        ci_id: 0,
        amount_due: 0,
        due_loan_savings: '',

        last_name_co: '',
        first_name_co: '',
        middle_name_co: '',
        permanent_address_co: '',
        total_net: '0',
        nmapi: '0',
        misc: '0',
        member_to_loan: {},
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member_co: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member_co == '') {
            this.search_items_co = []
          } else {
            this.searching_search_items_co(this.search_member_co)
          }
        }, 500)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id', 'user_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members_paid', 'search_members']),
      ...mapActions('loans_data', ['loan_initialize_data', 'is_member_have_unpaid_loans', 'register_new_loan', 'reconstructed_loan']),
      reset() {
        this.loan_insurance = '0'
        this.loan_processing_fee = '0'
        this.mortuary_insurance = '250'
        this.share_capital = '0'
        this.foundation = '100'

        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.croppieImage = ''
        this.cropped = this.avatarImg

        this.croppieImage_bus = ''
        this.cropped_bus = this.avatarImg

        this.croppieImage_home = ''
        this.cropped_home = this.avatarImg

        this.search = ''
        this.search_member = ''
        this.search_items = []

        this.search_co = ''
        this.search_member_co = ''
        this.search_items_co = []

        this.laf_no = ''
        this.last_name = ''
        this.first_name = ''
        this.middle_name = ''
        this.date_of_birth = ''
        this.place_of_birth = ''
        this.height = ''
        this.weight = ''
        this.tin_no = ''
        this.sss_no = ''
        this.type_of_id = ''
        this.id_no = ''
        this.civil_status = ''
        this.no_of_dependents = ''
        this.gender = ''
        this.present_address = ''
        this.residence_is = ''
        this.residence_specify = ''
        this.permanent_address = ''
        this.religion = ''
        this.citizenship = ''
        this.contact_no = ''
        this.email_address = ''
        this.occupation = ''
        this.employment_date = ''
        this.employer = ''
        this.employer_address = ''
        this.employer_contact_no = ''
        this.monthly_income = ''
        this.employment_status = ''
        this.family_last_name = ''
        this.family_first_name = ''
        this.family_middle_name = ''
        this.family_date_of_birth = ''
        this.family_contact_no = ''
        this.family_occupation = ''
        this.family_employer = ''
        this.family_employer_address = ''
        this.family_monthly_income = ''
        this.family_employment_status = ''
        this.family_employment_date = ''
        this.is_with_signature = true

        this.purpose_of_loan = ''
        this.type_of_loan = ''
        this.type_of_loan_others = ''
        this.loan_amount = ''
        this.mode_of_loan = ''
        this.terms = ''
        this.due_amount = ''
        this.interest = ''
        this.loan_savings = ''
        this.total = ''
        this.ci_items = []
        this.ci_id = 0
        this.amount_due = 0
        this.due_loan_savings = ''

        this.last_name_co = ''
        this.first_name_co = ''
        this.middle_name_co = ''
        this.permanent_address_co = ''

        this.loan_counter_data()
      },
      loan_counter_data() {
        if (Object.keys(this.value).length > 0) {
          this.type_of_loan = this.value.type_of_loan
          this.loan_amount = this.prev_balance
          this.purpose_of_loan = this.value.purpose_of_loan
          this.laf_no = this.value.laf_no
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      calculation_of_loan() {
        var terms = 0
        var interest = 0
        if (this.terms != 0) {
          var split_terms = this.terms.split(' ')
          terms = split_terms[0]
        }
        var savings_percentage = 0.05
        var interest_percentage = 0.028
        var loan_processing_percent = 0.05
        this.mortuary_insurance = '250'
        this.foundation = '100'
        // if (moment(this.date_accomplished, 'YYYY-MM-DD') > moment('2023-06-25', 'YYYY-MM-DD')) {
        //   this.foundation = '100'
        // }

        if (Object.keys(this.member_info).length > 0) {
          if (this.member_info.organization_history.length > 0) {
            this.is_calculator_committee = true
            this.is_deminishing = false
            interest_percentage = 0.01
            loan_processing_percent = 0.02
          }
        } else {
          this.is_deminishing = true
          this.is_calculator_committee = false
          interest_percentage = 0.028
        }
        var addition_misc = 0
        if (this.type_of_loan === 'Damayan') {
          interest_percentage = 0.0175
          this.mortuary_insurance = '0'
          this.foundation = '0'
          addition_misc = (this.loan_amount * 0.11)
        }

        interest = (this.loan_amount * interest_percentage) * terms
        if (Object.keys(this.member_info).length > 0) {
          if (this.member_info.organization_history.length === 0 && (this.member_info.member_status === 'Regular Member' || this.member_info.member_status === 'Associate Member')) {
            this.is_deminishing = true
          }
        }
        if (this.is_deminishing) {
          interest = 0;
          //Deminishing
          var int_per_mode = interest_percentage
          var interest_arr = []
          var premium_arr = []
          var tot_ter = 0
          switch (this.mode_of_loan) {
            case "Weekly":
              tot_ter = terms * 4
              int_per_mode = interest_percentage / 4
              break
            case "15/30":
              tot_ter = terms * 2
              int_per_mode = interest_percentage / 2
              break
            case "Monthly":
              tot_ter = terms
              int_per_mode = interest_percentage
              break
          }
          var principal_due = parseFloat((this.loan_amount / tot_ter)).toFixed(2)
          if (principal_due > 0) {
            var balanceee_due = parseFloat(this.loan_amount).toFixed(2)

            for (var x = 1; x <= tot_ter; x++) {
              interest += parseFloat(balanceee_due).toFixed(2) * int_per_mode
              var int_per_month = (parseFloat(balanceee_due).toFixed(2) * int_per_mode)
              interest_arr.push(parseFloat(int_per_month).toFixed(2))
              premium_arr.push(parseFloat(parseFloat(principal_due) + parseFloat(int_per_month)).toFixed(2))
              balanceee_due -= parseFloat(principal_due).toFixed(2)
            }
          }
          this.interest_per_month = interest_arr
          this.premium_per_month = premium_arr
          //EndDeminishing
        }

        this.misc = addition_misc + ''
        this.nmapi = 0
        if (this.type_of_loan === 'Tie-Up') {
          this.nmapi = (this.loan_amount * 0.02) + ''
        }

        this.loan_processing_fee = (this.loan_amount * loan_processing_percent)

        var savings = (this.loan_amount * savings_percentage)

        var term_loan_insurance = terms
        if (term_loan_insurance <= 12) {
          term_loan_insurance = 12;
        }
        var total_to_compute_loan_insurance = parseFloat(this.loan_amount) + (interest);
        var provider = ((total_to_compute_loan_insurance * 1.1) * term_loan_insurance) / 1000;
        var company = ((total_to_compute_loan_insurance * 1.1) * term_loan_insurance) / 1000;
        var commission = (provider * 0.2);
        this.loan_insurance_comm = parseFloat(commission)
        if (company < 300) {
          company = 300
        }
        this.loan_insurance = company;

        this.loan_savings = this.formatPrice(savings)

        this.interest = interest
        this.interestt = interest
        this.loan_savings = savings
        this.loan_savingss = savings
        this.due_loan_savings = savings.toString()
        this.due_interest = interest
        this.total = parseFloat(this.loan_amount) + (interest + savings) + parseFloat(addition_misc)
        this.totall = this.total

        var due_date = moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY')

        switch (this.mode_of_loan) {
          case "Weekly":
            this.amount_due = ((this.total / terms) / 4)
            due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(1, "week").format('MMMM D, YYYY')
            this.due_loan_savings = ((savings / terms) / 4).toString()
            this.due_interest = ((interest / terms) / 4).toString()
            break
          case "15/30":
            this.amount_due = ((this.total / terms) / 2)
            due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(15, "day").format('MMMM D, YYYY')
            this.due_loan_savings = ((savings / terms) / 2).toString()
            this.due_interest = ((interest / terms) / 2).toString()
            break
          case "Monthly":
            this.amount_due = ((this.total / terms))
            due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(1, "months").format('MMMM D, YYYY')
            this.due_loan_savings = (savings / terms).toString()
            this.due_interest = (interest / terms).toString()
            break
        }
        this.due_date = due_date
        this.due_amount = this.formatPrice(this.amount_due)
        this.interest = this.formatPrice(interest)
        this.total = this.formatPrice(this.total)
        this.loan_savings = this.formatPrice(savings)
        var net_proceeds = (parseFloat(this.loan_amount)) - (parseFloat(this.loan_insurance) + parseFloat(this.loan_processing_fee) + parseFloat(this.mortuary_insurance) + parseFloat(this.share_capital) + parseFloat(this.regular_savings) + parseFloat(this.foundation) + parseFloat(this.nmapi))
        this.total_net = this.formatPrice(net_proceeds)
      },
      // calculation_of_loan() {
      //   var savings_percentage = 0.05
      //   var interest_percentage = 0.025
      //   var loan_processing_percent = 0.03
      //
      //   if (this.type_of_loan === 'Damayan') {
      //     interest_percentage = 0.0175
      //   }
      //   if (this.type_of_loan === 'Business') {
      //     interest_percentage = 0.02
      //   }
      //   var terms = 0
      //   if (this.terms != 0) {
      //     var split_terms = this.terms.split(' ')
      //     terms = split_terms[0]
      //   }
      //
      //
      //   this.loan_processing_fee = (this.loan_amount * loan_processing_percent)
      //   var interest = (this.loan_amount * interest_percentage) * terms
      //   var savings =(this.loan_amount * savings_percentage)
      //
      //   var term_loan_insurance = terms
      //   if (parseInt(term_loan_insurance) <= 12) {
      //     term_loan_insurance = 12;
      //   }
      //   var total_to_compute_loan_insurance = parseFloat(this.loan_amount) + (interest);
      //   var provider = ((total_to_compute_loan_insurance * 1.1) * term_loan_insurance) / 1000;
      //   var company = ((total_to_compute_loan_insurance * 1.1) * term_loan_insurance) / 1000;
      //   var commission = (provider * 0.2);
      //   this.loan_insurance_comm = parseFloat(commission)
      //   if (company < 300) {
      //     company = 300
      //   }
      //   this.loan_insurance = company;
      //
      //   this.loan_savings = this.formatPrice(savings)
      //
      //   this.interest = interest
      //   this.interestt = interest
      //   this.loan_savings = savings
      //   this.loan_savingss = savings
      //   this.due_loan_savings = savings.toString()
      //   this.due_interest = interest
      //   this.total = parseFloat(this.loan_amount) + (interest + savings)
      //   this.totall = this.total
      //
      //   var due_date = moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY')
      //   switch (this.mode_of_loan) {
      //     case "Weekly":
      //       this.amount_due = ((this.total / terms) / 4)
      //       due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(1, "week").format('MMMM D, YYYY')
      //       this.due_loan_savings = ((savings / terms) / 4).toString()
      //       this.due_interest = ((interest / terms) / 4)
      //       break
      //     case "15/30":
      //       this.amount_due = ((this.total / terms) / 2)
      //       due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(15, "day").format('MMMM D, YYYY')
      //       this.due_loan_savings = ((savings / terms) / 2).toString()
      //       this.due_interest = ((interest / terms) / 2)
      //       break
      //     case "Monthly":
      //       this.amount_due = ((this.total / terms))
      //       due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(1, "months").format('MMMM D, YYYY')
      //       this.due_loan_savings = (savings / terms).toString()
      //       this.due_interest = (interest / terms)
      //       break
      //   }
      //   this.due_date = due_date
      //   this.due_amount = this.formatPrice(this.amount_due)
      //   this.interest = this.formatPrice(interest)
      //   this.total = this.formatPrice(this.total)
      //   this.loan_savings = this.formatPrice(savings)
      // },
      save_loan_application() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (Object.keys(this.value).length > 0) {
            const data = new FormData()
            data.append('loan_insurance_comm', this.loan_insurance_comm);
            data.append('loan_insurance_addtnl', this.loan_insurance_addtnl);
            data.append('loan_insurance', this.loan_insurance);
            data.append('loan_processing_fee', this.loan_processing_fee);
            data.append('mortuary_insurance', this.mortuary_insurance);
            data.append('share_capital', this.share_capital);
            data.append('foundation', this.foundation);
            data.append('regular_savings', this.regular_savings);

            data.append('loan_app_id', this.value.id);
            data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
            data.append('due_date', this.due_date);
            data.append('type_of_loan', this.type_of_loan);
            data.append('purpose_of_loan', this.purpose_of_loan);
            data.append('mode_of_loan', this.mode_of_loan);
            var splited_terms_loans = this.terms.split(' ')
            data.append('terms', splited_terms_loans[0]);
            data.append('amount', this.loan_amount);
            data.append('interest', this.interestt);

            data.append('interest_per_month', JSON.stringify(this.interest_per_month));
            data.append('premium_per_month', JSON.stringify(this.premium_per_month));
            data.append('is_deminishing', this.is_deminishing?1:0);

            data.append('due_interest', this.due_interest);
            data.append('loan_savings', this.loan_savingss);
            data.append('due_loan_savings', this.due_loan_savings);
            data.append('total', this.totall);
            data.append('amount_due', this.amount_due);
            data.append('principal', (parseFloat(this.amount_due) - (parseFloat(this.due_interest) + (parseFloat(this.due_loan_savings)))));
            data.append('interest', this.due_interest);
            data.append('savings', this.due_loan_savings);
            data.append('month_of', this.month_of);
            data.append('created_id', this.user_id);
            this.reconstructed_loan(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'warning',
                  text: response.data,
                })
                this.$emit('dialog_value', false)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
