<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LOANS SAVINGS HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Application #
            </th>
            <th class="text-center text-uppercase">
              Month Paid
            </th>
            <th class="text-center text-uppercase">
              Loan Savings
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td v-if="item.paid === 1" class="text-center">{{ item.laf_no }}</td>
            <td class="text-center" v-if="item.paid === 1">
              {{ item.month_of_paid }}
            </td>
            <td class="text-center" v-if="item.paid === 1">
              {{ formatPrice(item.loan_savings) }}
            </td>
            <td class="text-center">
              <div v-if="item.is_loan_savings_withdraw === 1">
                <v-chip color="success" dark>WITHDREW</v-chip>
              </div>
              <div v-else>
                <v-chip color="warning" dark>SAVINGS</v-chip>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>

  export default {
    props: {
      data: Array,
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
    },
  }
</script>
