<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SHARE CAPITAL HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Deposit Date
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
               Year
            </th>
            <th class="text-center text-uppercase">
              Balance
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td class="text-center">
              {{ item.or_date }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ date_format(item.effective_date).format('YYYY') }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.balance) }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import moment from "moment";
  export default {
    props: {
      data: Array,
    },
    methods: {
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
    },
  }
</script>
