<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SEARCH MEMBERS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          item-value="id"
          item-text="name"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
      </v-card-text>
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
        >
          <v-icon
            size="20"
            class="me-3"
          >
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <!-- tabs item -->
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <account
            v-on:data="reset"
            :cropped="cropped"
            :branch="branch"
            :data="member_information"
          ></account>
        </v-tab-item>
        <v-tab-item>
          <v-tabs
            v-model="loan_tab"
            show-arrows
          >
            <v-tab
              v-for="tab in loan_tabs"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="loan_tab">
            <v-tab-item>
              <money-loans
                v-on:reset_data="reset()"
                :data="loans_history"
                :member_data="member_information"></money-loans>
            </v-tab-item>
            <v-tab-item>
              <gadget-loans
                :data="gadget_loans_history"
                :member_data="member_information"></gadget-loans>
            </v-tab-item>
          </v-tabs-items>
        </v-tab-item>
        <v-tab-item>
          <time-deposit
            :data="time_deposit_history"
          ></time-deposit>
        </v-tab-item>
        <v-tab-item>
          <loan-savings
            :data="loans_history"
          ></loan-savings>
        </v-tab-item>
        <v-tab-item>
          <share-capital
            :data="share_capital_history"
          ></share-capital>
        </v-tab-item>
        <v-tab-item>
          <mortuary-insurance
            :data="mortuary_insurance_history"
          ></mortuary-insurance>
        </v-tab-item>
        <v-tab-item>
          <foundation
            :data="foundation_history"
          ></foundation>
        </v-tab-item>
        <v-tab-item>
          <raw-lots
            :data="raw_lots_history"
            :member_data="member_information"></raw-lots>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiInformationOutline, mdiAccountCashOutline
    , mdiCash, mdiCellphone, mdiClipboardClockOutline, mdiContentSaveMoveOutline,
    mdiCashRefund, mdiShieldAccount, mdiGoogleCirclesCommunities, mdiBallotOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import Account from './member_components/Account'
  import MoneyLoans from './member_components/MoneyLoans'
  import GadgetLoans from './member_components/GadgetLoans'
  import TimeDeposit from './member_components/TimeDeposit'
  import LoanSavings from './member_components/LoanSavings'
  import ShareCapital from './member_components/ShareCapital'
  import MortuaryInsurance from './member_components/MortuaryInsurance'
  import Foundation from './member_components/Foundation'
  import RawLots from './member_components/RawLots'

  export default {
    components: {
      Account,
      MoneyLoans,
      GadgetLoans,
      TimeDeposit,
      LoanSavings,
      ShareCapital,
      MortuaryInsurance,
      Foundation,
      RawLots,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
        },
      }
    },
    data() {
      return {
        search: '',
        search_member: '',
        search_items: [],

        cropped: this.avatarImg,
        branch: '',
        member_information: {},
        loans_history: [],
        gadget_loans_history: [],
        time_deposit_history: [],
        share_capital_history: [],
        mortuary_insurance_history: [],
        foundation_history: [],
        raw_lots_history: [],

        tab: '',
        loan_tab: '',
        tabs: [
          {title: 'INFORMATIONS', icon: mdiAccountOutline},
          {title: 'LOANS', icon: mdiAccountCashOutline},
          {title: 'TIME DEPOSITS', icon: mdiClipboardClockOutline},
          {title: 'LOAN SAVINGS', icon: mdiContentSaveMoveOutline},
          {title: 'SHARE CAPITAL', icon: mdiCashRefund},
          {title: 'INSURANCE', icon: mdiShieldAccount},
          {title: 'FOUNDATION', icon: mdiGoogleCirclesCommunities},
          {title: 'RAW LOTS', icon: mdiBallotOutline},
        ],
        loan_tabs: [
          {title: 'MONEY', icon: mdiCash},
          {title: 'APPLIANCES/GADGETS', icon: mdiCellphone},
        ],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('members_information', ['search_all_members']),
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_all_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      reset() {
        this.search_member = ''
        this.search_items = []
        this.cropped = this.avatarImg
        this.branch = ''
        this.member_information = {}
        this.loans_history = []
        this.gadget_loans_history = []
        this.time_deposit_history = []
        this.share_capital_history = []
        this.mortuary_insurance_history = []
        this.foundation_history = []
        this.raw_lots_history = []
      },
      get_search_items_info() {
        this.amount = 0
        this.amount_due = []
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.cropped = this.search_items[index].image
            this.branch = this.search_items[index].branch
            this.member_information = this.search_items[index].member
            this.loans_history = this.search_items[index].loans
            this.gadget_loans_history = this.search_items[index].gadget_loans
            this.time_deposit_history = this.search_items[index].time_deposit
            this.share_capital_history = this.search_items[index].share_capital
            this.mortuary_insurance_history = this.search_items[index].mortuary_insurance
            this.foundation_history = this.search_items[index].foundation
            this.raw_lots_history = this.search_items[index].raw_lots
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
    }
  }
</script>
