<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">TIME DEPOSITS HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Application #
            </th>
            <th class="text-center text-uppercase">
              Date
            </th>
            <th class="text-center text-uppercase">
              Term
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Interest
            </th>
            <th class="text-center text-uppercase">
              Total
            </th>
            <th class="text-center text-uppercase">
              Expected Maturity
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase">
              Breakdown
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>{{ item.tdf_no }}</td>
            <td class="text-center">
              {{ item.date_of_savings }}
            </td>
            <td class="text-center">
              {{ item.terms }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.interest) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.total) }}
            </td>
            <td class="text-center">
              {{ item.expected_month }}
            </td>
            <td class="text-center">
              <div v-if="item.released === 1">
                <v-chip color="success" dark>RELEASED</v-chip>
              </div>
              <div v-else>
                <v-chip color="warning" dark>PENDING</v-chip>
              </div>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="soa_data(item)"
              >
                {{icons.mdiFileEye}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="soa_show" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">TIME DEPOSITS BREAKDOWN COMPUTATION</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                Date
              </th>
              <th class="text-center text-uppercase">
                Amount
              </th>
              <th class="text-center text-uppercase">
                Interest
              </th>
              <th class="text-center text-uppercase">
                Total
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in time_deposit_breakdown"
              :key="item.id"
            >
              <td>{{ item.date }}</td>
              <td class="text-center">
                {{ formatPrice(item.amount)}}
              </td>
              <td class="text-center">
                {{ formatPrice(item.interest) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.total) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiPrinter, mdiFileEye} from '@mdi/js'

  export default {
    props: {
      data: Array,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/logos/main-logo.png'),
        icons: {
          mdiPrinter,
          mdiFileEye,
        },
      }
    },
    data() {
      return {
        soa_show: false,
        time_deposit_breakdown: [],
      }
    },
    watch: {
      soa_show: function () {
        if (!this.soa_show) {
          this.time_deposit_breakdown = []
        }
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      soa_data(data) {
        this.soa_show = true
        this.time_deposit_breakdown = data.time_deposit_soa
      },
    },
  }
</script>
