<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">RAW LOTS HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Location
            </th>
            <th class="text-center text-uppercase">
              Block #
            </th>
            <th class="text-center text-uppercase">
              Lot #
            </th>
            <th class="text-center text-uppercase">
              Months
            </th>
            <th class="text-center text-uppercase">
              SQM
            </th>
            <th class="text-center text-uppercase">
              Due Amount
            </th>
            <th class="text-center text-uppercase">
              Total
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase">
              SOA
            </th>
            <th class="text-center text-uppercase">
              Sched
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>{{ item.location_data.location.location_code }}</td>
            <td class="text-center">
              {{ item.location_data.block_no }}
            </td>
            <td class="text-center">
              {{ item.location_data.lot_no }}
            </td>
            <td class="text-center">
              {{ item.month_of }}
            </td>
            <td class="text-center">
              {{ item.location_data.square_meter}}
            </td>
            <td class="text-center">
              {{ formatPrice(item.location_data.amount) }}
            </td>
            <td class="text-center" v-if="item.location_data.years===8">
              {{ formatPrice(item.location_data.amount*100) }}
            </td>
            <td class="text-center" v-else>
              {{ formatPrice(item.location_data.amount*72) }}
            </td>
            <td class="text-center">
              <div v-if="item.forfeited===1">
                <v-chip color="success" dark>FORFEITED</v-chip>
              </div>
              <div v-else-if="item.location_data.paid === 1">
                <v-chip color="success" dark>PAID</v-chip>
              </div>
              <div v-else>
                <v-chip color="warning" dark>ACTIVE</v-chip>
              </div>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="soa_data(item.location_data,item.raw_lots_payment,item)"
              >
                {{icons.mdiFileEye}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                @click="print_loan_schedule(item)"
              >
                {{icons.mdiAccountClock}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="soa_show" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">RAW LOTS STATEMENT OF ACCOUNT (SOA)</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="8"
              class="pt-6"
            >
              <v-text-field label="Location"
                            v-model="location.location_code"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pt-6"
            >
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Block #"
                            v-model="location_data.block_no"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Lot #"
                            v-model="location_data.lot_no"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="SQM"
                            v-model="location_data.square_meter"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Due Amount"
                            readonly
                            v-model="amount_due"
                            outlined
                            dense
                            background-color="info"
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Total"
                            readonly
                            v-model="total_loan"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Balance"
                            background-color="warning"
                            readonly
                            v-model="balance"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">RAW LOTS PAYMENT</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <h4 class="font-weight-light">Print SOA:</h4>
          <v-icon
            class="mr-2"
            @click="print_soa()"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center text-uppercase">
                OR Date
              </th>
              <th class="text-center text-uppercase">
                OR No
              </th>
              <th class="text-center text-uppercase">
                Amount
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in raw_lots_payment"
              :key="item.id"
            >
              <td>{{ item.or_date }}</td>
              <td class="text-center">
                {{ item.or_no }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mdiPrinter, mdiFileEye, mdiAccountCash, mdiAccountClock} from '@mdi/js'
  import {mapGetters} from "vuex";
  import moment from 'moment'

  export default {
    props: {
      data: Array,
      member_data: Object,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/logos/main-logo.png'),
        icons: {
          mdiPrinter,
          mdiFileEye,
          mdiAccountCash,
          mdiAccountClock,
        },
      }
    },
    data() {
      return {
        assumme_show: false,
        soa_show: false,
        location_data: {},
        location: {},
        loans_information: [],
        raw_lots_payment: [],
        balance: 0,
        prev_balance: 0,

        total_loan: 0,
        total_payed: 0,
        amount_due: 0,

        assume_by: '',
        data_temp: {},
        total_months: 0,
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_address', 'branch_contact_no', 'position']),
      ...mapGetters('system_data', ['company_logo']),
    },
    watch: {
      soa_show: function () {
        if (!this.soa_show) {
          this.location_data = {}
          this.location = {}
          this.raw_lots_payment = []
          this.data_temp = {}
          this.amount_due = 0
          this.balance = 0
          this.assume_by = ''
          this.total_months = 0
          this.total_payed = 0
          this.total_loan = 0
        }
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      soa_data(data, payment, item) {
        this.soa_show = true
        this.location_data = data
        this.location = data.location
        this.raw_lots_payment = payment
        this.data_temp = item
        var total_payment = 0;
        if (this.raw_lots_payment.length > 0) {
          this.raw_lots_payment.forEach(function (item) {
              total_payment += parseFloat(item.amount)
            }
          )
        }
        if (data.years === 8) {
          this.total_months = 100
          this.balance = this.formatPrice(parseFloat(data.amount * 100) - parseFloat(total_payment));
          this.total_loan = this.formatPrice(data.amount * 100)
        } else {
          this.total_months = 72
          this.balance = this.formatPrice(parseFloat(data.amount * 72) - parseFloat(total_payment));
          this.total_loan = this.formatPrice(data.amount * 72)
        }
        this.total_payed = this.formatPrice(total_payment)
        this.amount_due = this.formatPrice(data.amount)
      },
      print_soa() {
        var imgData = this.company_logo
        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Or Date', alignment: 'left', style: 'label'},
            {text: 'OR #', alignment: 'left', style: 'label'},
            {text: 'Amount', alignment: 'left', style: 'label'},
          ]
        )
        if (this.raw_lots_payment.length > 0) {
          this.raw_lots_payment.forEach(function (item) {
              accounts_array.push(
                [
                  {text: item.or_date, alignment: 'left'},
                  {text: item.or_no.substring(0, item.or_no.indexOf('-')), alignment: 'left'},
                  {text: item.amount, alignment: 'left'},
                ]
              )
            }
          )
        } else {
          accounts_array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.location_data.branch.address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.location_data.branch.contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'RAW LOTS STATEMENT OF ACCOUNT (SOA)', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              columns: [
                {
                  text: 'Branch: ' + this.location_data.branch.branch_code,
                  style: 'main_info'
                },
                {
                  text: 'Date Accomplished: ' + this.data_temp.date_accomplished,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'MEMBERS PERSONAL INFORMATION',
            ' ',
            {
              columns: [
                {
                  text: 'Applicant Name: ' + this.member_data.last_name + ', ' + this.member_data.first_name + ' ' + this.member_data.middle_name,
                  style: 'main_info'
                },
                {
                  text: 'Contact #: ' + this.member_data.contact_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Present Address: ' + this.member_data.present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'RAW LOTS DATA',
            ' ',
            {
              columns: [
                {text: this.location_data.block_no, style: 'main_info'},
                {text: this.location_data.lot_no, style: 'main_info'},
                {text: this.location_data.square_meter, style: 'main_info'},
                {text: '|', style: 'main_info'},
                {text: '( ' + this.total_months + ' months', style: 'main_info'},
                {text: 'x ' + this.location_data.amount + ' ) =', style: 'main_info'},
                {text: ' ' + this.total_loan + ' - ', style: 'main_info'},
                {text: this.total_payed + ' =', style: 'main_info'},
                {text: this.balance, style: 'main_info', color: 'red'},
              ]
            },
            {
              columns: [
                {text: 'Block #', style: 'sub_info'},
                {text: 'Lot #', style: 'sub_info'},
                {text: 'SQM', style: 'sub_info'},
                {text: '', style: 'main_info'},
                {text: 'Due Amount', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
                {text: 'Payed', style: 'sub_info'},
                {text: 'Balance', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [250, 130, 130],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
      print_loan_schedule(data) {

        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Due Date', alignment: 'left', style: 'label'},
            {text: 'Savings', alignment: 'left', style: 'label'},
            {text: 'Loan', alignment: 'left', style: 'label'},
            {text: 'Due Amount', alignment: 'left', style: 'label'},
            {text: 'Balance', alignment: 'left', style: 'label'}
          ]
        )
        var maximum_due = 0
        var partial_balance = data.total
        var loan_savings = 0
        var partial_loan_due_amount = 0
        var due_date = data.date_accomplished
        var amount_due = data.amount_due

        accounts_array.push(
          [
            {text: due_date, alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(partial_balance), alignment: 'left'},
          ]
        )
        switch (data.mode_of_loan) {
          case "Weekly":
            maximum_due = parseFloat(data.terms) * 4
            loan_savings = parseFloat(data.loan_savings) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - loan_savings)
            for (var i = 1; i <= maximum_due; i++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(7, 'days').format('MMMM D, YYYY')
              partial_balance = partial_balance - data.amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(partial_loan_due_amount), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          case "15/30":
            maximum_due = parseFloat(data.terms) * 2
            loan_savings = parseFloat(data.loan_savings) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - loan_savings)
            for (var x = 1; x <= maximum_due; x++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(15, 'days').format('MMMM D, YYYY')
              partial_balance = partial_balance - data.amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(partial_loan_due_amount), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          case "Monthly":
            maximum_due = parseFloat(data.terms)
            loan_savings = parseFloat(data.loan_savings) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - loan_savings)
            for (var j = 1; j <= maximum_due; j++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(1, 'months').format('MMMM D, YYYY')
              partial_balance = partial_balance - data.amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(partial_loan_due_amount), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          default:
            break;
        }

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: this.company_logo, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: data.branch.address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + data.branch.contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LOAN SCHEDULE OF PAYMENT', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              columns: [
                {text: 'Branch: ' + data.branch.branch_code, style: 'main_info'},
                {
                  text: 'Date Accomplished: ' + data.date_accomplished,
                  style: 'main_info'
                },
                {
                  text: 'LAF #: ' + data.laf_no,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'MEMBERS PERSONAL INFORMATION',
            ' ',
            {
              columns: [
                {
                  text: 'Applicant Name: ' + this.member_data.last_name + ', ' + this.member_data.first_name + ' ' + this.member_data.middle_name,
                  style: 'main_info'
                },
                {
                  text: 'Contact #: ' + data.loans_information[0].contact_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Present Address: ' + data.loans_information[0].present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'CO MAKER INFORMATION',
            ' ',
            {
              columns: [
                {text: 'CO - MAKER NAME', style: 'main_info'},
                {text: 'CO - MAKER CONTACT #', style: 'main_info'},
                {text: 'CO - MAKER ADDRESS', style: 'main_info'},
              ]
            },
            {
              columns: [
                {
                  text: data.loans_information[0].co_maker.last_name + ', ' + data.loans_information[0].co_maker.first_name,
                  style: 'main_info'
                },
                {
                  text: data.loans_information[0].co_maker.contact_no,
                  style: 'main_info'
                },
                {
                  text: data.loans_information[0].co_maker.permanent_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'LOAN DATA',
            ' ',
            {
              columns: [
                {text: data.mode_of_loan, style: 'main_info'},
                {text: data.terms + ' month/s', style: 'main_info'},
                {text: '|', style: 'main_info'},
                {
                  text: this.formatPrice(data.amount) + '     +',
                  style: 'main_info'
                },
                {
                  text: this.formatPrice(data.interest) + '     +',
                  style: 'main_info'
                },
                {
                  text: this.formatPrice(data.loan_savings) + '     =',
                  style: 'main_info'
                },
                {text: this.formatPrice(data.total), style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: 'Mode', style: 'sub_info'},
                {text: 'Terms', style: 'sub_info'},
                {text: '|', style: 'main_info'},
                {text: 'Loan Amount', style: 'sub_info'},
                {text: 'Interest', style: 'sub_info'},
                {text: 'Loan Savings', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [130, 90, 90, 90, 90],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
    },
  }
</script>
