<template>
  <v-dialog v-model="dialog_show" persistent width="70%">
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ASSUME LOAN APPLICATION</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                type="date"
                v-model="date_accomplished"
                label="Date Accomplished"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                @keyup="calculation_of_loan"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="laf_no"
                label="LAF No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-combobox
                    class="mx-2"
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search"
                    @keyup="searching($event.target.value)"
                    @change="get_search_items_info"
                    :items="search_items"
                    item-value="id"
                    item-text="name"
                    placeholder="Search by (Lastname/Firstname/CMF #)"
                    ref="memberCombobox"
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="place_of_birth"
                      dense
                      label="Place of Birth"
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="height"
                      type="number"
                      dense
                      label="Height"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="weight"
                      type="number"
                      dense
                      label="Weight"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>


                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="tin_no"
                      dense
                      outlined
                      label="Tax ID Number (TIN)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="sss_no"
                      dense
                      outlined
                      label="SSS Number"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="type_of_id"
                      dense
                      outlined
                      label="Type of ID"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="id_no"
                      dense
                      outlined
                      label="ID #"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-row cols="12"
                         md="2" class="pt-6">
                    <v-avatar
                      rounded
                      size="40"
                      class="me-6"
                    >
                      <v-img :src="cropped"></v-img>
                    </v-avatar>
                    <v-btn
                      color="primary"
                      @click="$refs.refInputEl.click()"
                    >
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Upload</span>
                    </v-btn>
                    <input
                      ref="refInputEl"
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                      v-on:click="$refs.refInputEl.value = ''"
                      v-on:change="croppie"
                    />
                  </v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single','Married','Widowed','Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="no_of_dependents"
                      dense
                      outlined
                      label="No. Of Dependents"
                      type="number"
                      min="0"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male','Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="present_address"
                      dense
                      outlined
                      label="Present Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-select
                      v-model="residence_is"
                      dense
                      outlined
                      label="Residence is"
                      :items="['Owned','Rented','Mortgaged','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-if="residence_is=='Others'"
                      v-model="residence_specify"
                      dense
                      outlined
                      label="Specify (if others)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="9"
                  >
                    <v-text-field
                      v-model="permanent_address"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="citizenship"
                      dense
                      outlined
                      label="Citizenship"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="contact_no"
                      dense
                      outlined
                      label="Contact Number/s"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="email_address"
                      dense
                      outlined
                      label="Email Address"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">EMPLOYMENT/BUSINESS INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="occupation"
                      label="Occupation"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="employment_date"
                      label="Length of Service/Date Started"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="employer"
                      label="Employer/Business Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="employer_address"
                      label="Employer/Business Address"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="employer_contact_no"
                      dense
                      label="Contact Number/s"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-select
                      v-model="monthly_income"
                      dense
                      outlined
                      label="Which of the following comes closest to your monthly income in pesos"
                      :items="['Under 8,000','8,000-15,000','15,001-30,000','30,001-50,000','50,001-100,000','Above 100,000']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="employment_status"
                      dense
                      outlined
                      label="Employment Status"
                      :items="['Regular/Permanent','Contractual','Self Employed','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">SPOUSE'S INFORMATION/FAMILY
                  REPRESENTATIVE</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="family_last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="family_first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="family_middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_contact_no"
                      dense
                      label="Contact Number/s"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_occupation"
                      dense
                      label="Occupation"
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_employer"
                      dense
                      outlined
                      label="Employer/Business"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-text-field
                      v-model="family_employer_address"
                      dense
                      outlined
                      label="Business/Employer's Address/Contact Number"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_monthly_income"
                      dense
                      outlined
                      label="Monthly Income (in peso)"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_employment_status"
                      dense
                      outlined
                      label="Employment Status"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="family_employment_date"
                      dense
                      outlined
                      label="Length of Service/Date Started"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
              dense
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">Loan Data</h4></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-6"
                  >
                    <v-select
                      :items="['Continue Loan', 'Reconstruct Loan']"
                      label="Assumed Status"
                      required
                      :rules="rules.combobox_rule"
                      v-model="assumed_status"
                      @change="calculation_of_loan"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"

                  >
                    <v-select
                      :items="['Laptop','Cellphone']"
                      label="Type of Appliance/Gadget"
                      required
                      :rules="rules.combobox_rule"
                      v-model="type_of_gadget"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="pt-6"
                  >
                    <v-text-field label="Specify"
                                  :rules="rules.default_max_45_character_and_no_empty_rule"
                                  v-model="type_of_gadget_others"
                                  v-if="type_of_gadget === 'Other'"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-row cols="12"
                         md="3" class="pt-9"
                         v-if="type_of_gadget === 'Business'">
                    <v-avatar
                      rounded
                      size="40"
                      class="me-6"
                    >
                      <v-img :src="cropped_bus"></v-img>
                    </v-avatar>
                    <v-btn
                      color="primary"
                      @click="$refs.refInputEl_bus.click()"
                    >
                      <v-icon class="d-sm-none">
                        {{ icons.mdiCloudUploadOutline }}
                      </v-icon>
                      <span class="d-none d-sm-block">Upload Permit</span>
                    </v-btn>
                    <input
                      ref="refInputEl_bus"
                      type="file"
                      accept=".jpeg,.png,.jpg,GIF"
                      :hidden="true"
                      v-on:click="$refs.refInputEl_bus.value = ''"
                      v-on:change="croppie_bus"
                    />
                  </v-row>
                  <v-col
                    cols="12"
                    md="3"
                    v-else
                  ></v-col>
                  <v-col
                    cols="12"
                    md="3"

                  >
                    <v-text-field label="Loan Balance"
                                  readonly
                                  v-model="loan_amount"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="assumed_status==='Reconstruct Loan'"
                  >
                    <v-select
                      :items="['Weekly', '15/30', 'Monthly']"
                      label="Mode of Loan"
                      required
                      :rules="rules.combobox_rule"
                      v-model="mode_of_loan"
                      @change="calculation_of_loan"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Prev. Terms"
                                  readonly
                                  v-model="prev_terms"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="assumed_status==='Reconstruct Loan'"
                  >
                    <v-select
                      :items="['1 month', '2 months', '3 months', '4 months', '5 months', '6 months', '7 months', '8 months', '9 months', '10 months', '11 months', '12 months', '13 months', '14 months', '15 months', '16 months', '17 months', '18 months', '19 months', '20 months', '21 months', '22 months', '23 months', '24 months'
                                                            ,'25 months','26 months','27 months','28 months','29 months','30 months','31 months','32 months','33 months','34 months','35 months','36 months']"
                      label="Additional Terms of Loan"
                      required
                      :rules="rules.combobox_rule"
                      v-model="terms"
                      @change="calculation_of_loan"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="assumed_status==='Reconstruct Loan'"
                  >
                    <v-text-field label="Additional Interest"
                                  readonly
                                  v-model="interest"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"

                  >
                    <v-text-field label="Due Amount"
                                  readonly
                                  v-model="due_amount"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field label="Prev. Total"
                                  readonly
                                  v-model="prev_total"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="assumed_status==='Reconstruct Loan'"
                  >
                    <v-text-field label="New Total"
                                  readonly
                                  v-model="total"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">CO-MAKER INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-combobox
                    class="mx-2"
                    :append-icon="icons.mdiAccountSearchOutline"
                    v-model="search_co"
                    @keyup="searching_co($event.target.value)"
                    @change="get_search_items_info_co"
                    :items="search_items_co"
                    item-value="id"
                    item-text="name"
                    placeholder="Search by (Lastname/Firstname/CMF #)"
                    ref="memberCombobox2"
                    dense
                    outlined
                  ></v-combobox>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                ></v-col>
                <v-row>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="last_name_co"
                      label="Co-Maker Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="first_name_co"
                      label="Co-Maker First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="middle_name_co"
                      label="Co-Maker Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>


                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="permanent_address_co"
                      dense
                      outlined
                      label="Permanent Address"
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card
              flat
            >
              <v-card-text class="d-flex">
                <v-avatar
                  rounded
                  size="250"
                  class="me-6"
                >
                  <v-img :src="cropped_home"></v-img>
                </v-avatar>

                <!-- upload photo -->
                <div>
                  <v-btn
                    color="primary"
                    class="me-3 mt-5"
                    @click="$refs.refInputEl_home.click()"
                  >
                    <v-icon class="d-sm-none">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">Upload Scan Home Sketch / Place</span>
                  </v-btn>

                  <input
                    ref="refInputEl_home"
                    type="file"
                    accept=".jpeg,.png,.jpg,GIF"
                    :hidden="true"
                    v-on:click="$refs.refInputEl_home.value = ''"
                    v-on:change="croppie_home"
                  />

                  <v-btn
                    color="error"
                    outlined
                    class="mt-5"
                    @click="resett()"
                  >
                    Reset
                  </v-btn>
                  <p class="text-sm mt-5">
                    Allowed JPG or PNG
                  </p>
                </div>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="is_with_signature"
                label="With Signature"
                class="mt-0"
                hide-details
              ></v-checkbox>
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-btn
                color="error"
                class="me-3 mt-4"
                @click="close_dialog"
                v-if="!saving"
              >
                Close
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </v-dialog>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      dialog_show: Boolean,
      loans_data: Object,
      loans_information: Array,
      prev_balance: Number,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],

        search_co: '',
        search_member_co: '',
        search_items_co: [],

        croppieImage: '',
        cropped: this.avatarImg,
        croppieImage_bus: '',
        cropped_bus: this.avatarImg,
        croppieImage_home: '',
        cropped_home: this.avatarImg,

        date_accomplished: '',
        prev_terms: '',
        laf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        height: '',
        weight: '',
        tin_no: '',
        sss_no: '',
        type_of_id: '',
        id_no: '',
        civil_status: '',
        no_of_dependents: '',
        gender: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        religion: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        is_with_signature: true,

        type_of_gadget: '',
        assumed_status: '',
        type_of_gadget_others: '',
        loan_amount: '',
        mode_of_loan: '',
        terms: '',
        due_amount: '',
        interest: '',
        interestt: 0,
        due_interest: 0,
        loan_savings: '',
        loan_savingss: '',
        total: '',
        totall: '',
        prev_total: '',
        amount_due: 0,
        due_loan_savings: '',

        last_name_co: '',
        first_name_co: '',
        middle_name_co: '',
        permanent_address_co: '',

        prev_due_amount: 0,
        ci_id:0,
      }
    },
    mounted() {
      this.laf_no = this.loans_data.glaf_no
      this.loan_amount = this.prev_balance
      this.prev_due_amount = this.loans_data.amount_due
      this.due_amount = this.prev_due_amount
      this.prev_terms = this.loans_data.terms + ' month/s'
      this.prev_total = this.formatPrice(this.loans_data.total)
      this.mode_of_loan=this.loans_data.mode_of_loan
    },
    watch: {
      loans_data: function () {
        this.laf_no = this.loans_data.glaf_no
        this.loan_amount = this.prev_balance
        this.prev_due_amount = this.loans_data.amount_due
        this.due_amount = this.prev_due_amount
        this.prev_terms = this.loans_data.terms + ' month/s'
        this.prev_total = this.formatPrice(this.loans_data.total)
        this.mode_of_loan=this.loans_data.mode_of_loan
      },
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member_co: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member_co == '') {
            this.search_items_co = []
          } else {
            this.searching_search_items_co(this.search_member_co)
          }
        }, 500)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'month_start', 'month_end', 'month_of']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members_paid']),
      ...mapActions('loans_data', ['loan_initialize_data', 'is_member_have_unpaid_loans']),
      ...mapActions('gadget_loans_data_assumed', ['register_new_gadget_loan_assumed']),
      close_dialog() {
        this.reset()
        this.$emit('dialog_value', false)
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.croppieImage = ''
        this.cropped = this.avatarImg

        this.croppieImage_bus = ''
        this.cropped_bus = this.avatarImg

        this.croppieImage_home = ''
        this.cropped_home = this.avatarImg

        this.search = ''
        this.search_member = ''
        this.search_items = []

        this.search_co = ''
        this.search_member_co = ''
        this.search_items_co = []

        this.laf_no = ''
        this.last_name = ''
        this.first_name = ''
        this.middle_name = ''
        this.date_of_birth = ''
        this.place_of_birth = ''
        this.height = ''
        this.weight = ''
        this.tin_no = ''
        this.sss_no = ''
        this.type_of_id = ''
        this.id_no = ''
        this.civil_status = ''
        this.no_of_dependents = ''
        this.gender = ''
        this.present_address = ''
        this.residence_is = ''
        this.residence_specify = ''
        this.permanent_address = ''
        this.religion = ''
        this.citizenship = ''
        this.contact_no = ''
        this.email_address = ''
        this.occupation = ''
        this.employment_date = ''
        this.employer = ''
        this.employer_address = ''
        this.employer_contact_no = ''
        this.monthly_income = ''
        this.employment_status = ''
        this.family_last_name = ''
        this.family_first_name = ''
        this.family_middle_name = ''
        this.family_date_of_birth = ''
        this.family_contact_no = ''
        this.family_occupation = ''
        this.family_employer = ''
        this.family_employer_address = ''
        this.family_monthly_income = ''
        this.family_employment_status = ''
        this.family_employment_date = ''
        this.is_with_signature = true

        this.type_of_gadget = ''
        this.type_of_gadget_others = ''
        this.loan_amount = ''
        this.mode_of_loan = ''
        this.terms = ''
        this.due_amount = ''
        this.interest = ''
        this.loan_savings = ''
        this.total = ''
        this.prev_terms = ''
        this.prev_total = ''

        this.amount_due = 0
        this.due_loan_savings = ''

        this.last_name_co = ''
        this.first_name_co = ''
        this.middle_name_co = ''
        this.permanent_address_co = ''
        this.ci_id=0

      },
      croppie(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_bus(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_bus = this.croppieImage_bus = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_home(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_home = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      calculation_of_loan() {
        if (this.assumed_status === 'Reconstruct Loan') {
          var terms = 0
          if (this.terms != 0) {
            var split_terms = this.terms.split(' ')
            terms = split_terms[0]
          }

          var interest_percentage = 0.025
          if (this.type_of_gadget === 'Damayan') {
            interest_percentage = 0.0175
          }
          if (this.type_of_gadget === 'Business') {
            interest_percentage = 0.02
          }
          this.interest = (parseFloat(this.loans_data.amount) * interest_percentage) * parseFloat(terms)
          var tot = parseFloat(this.loans_data.total) + parseFloat(this.interest)
          this.total = this.formatPrice(tot)
          switch (this.mode_of_loan) {
            case "Weekly":
              this.amount_due = (((parseFloat(this.loan_amount)+parseFloat(this.interest)) / (parseFloat(terms)  + this.loans_data.terms)) / 4)
              break
            case "15/30":
              this.amount_due = (((parseFloat(this.loan_amount)+parseFloat(this.interest)) / (parseFloat(terms) + this.loans_data.terms)) / 2)
              break
            case "Monthly":
              this.amount_due = (((parseFloat(this.loan_amount)+parseFloat(this.interest)) / (parseFloat(terms)  + this.loans_data.terms)))
              break
          }
          this.due_amount = this.formatPrice(this.amount_due)
        } else {
          this.interest = 0
          this.amount_due = this.prev_due_amount
          this.due_amount = this.prev_due_amount
          this.total = this.loans_data.total
        }
      },
      searching(value) {
        this.search_member = value
      },
      searching_co(value) {
        this.search_member_co = value
      },
      searching_search_items(value) {
        this.search_members_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      searching_search_items_co(value) {
        this.search_members_paid({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items_co = response.data
          })
      },
      get_search_items_info() {
        this.amount = 0
        this.amount_due = []
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.last_name = this.search_items[index].member.last_name
            this.first_name = this.search_items[index].member.first_name
            this.middle_name = this.search_items[index].member.middle_name
            this.date_of_birth = moment(this.search_items[index].member.date_of_birth, 'MMMM D, YYYY').format('YYYY-MM-DD')
            this.place_of_birth = this.search_items[index].member.place_of_birth
            this.tin_no = this.search_items[index].member.tin_no
            this.sss_no = this.search_items[index].member.sss_no
            this.civil_status = this.search_items[index].member.civil_status
            this.no_of_dependents = this.search_items[index].member.no_of_dependents
            this.gender = this.search_items[index].member.gender
            this.present_address = this.search_items[index].member.present_address
            this.residence_specify = this.search_items[index].member.residence_specify
            this.permanent_address = this.search_items[index].member.permanent_address
            this.religion = this.search_items[index].member.religion
            this.citizenship = this.search_items[index].member.citizenship
            this.contact_no = this.search_items[index].member.contact_no
            this.email_address = this.search_items[index].member.email_address
            this.occupation = this.search_items[index].member.occupation
            this.employment_date = this.search_items[index].member.employment_date
            this.employer = this.search_items[index].member.employer
            this.employer_address = this.search_items[index].member.employer_address
            this.employer_contact_no = this.search_items[index].member.employer_contact_no
            this.family_last_name = this.search_items[index].member.family_last_name
            this.family_first_name = this.search_items[index].member.family_first_name
            this.family_middle_name = this.search_items[index].member.family_middle_name
            this.family_date_of_birth = this.search_items[index].member.family_date_of_birth
            this.family_contact_no = this.search_items[index].member.family_contact_no
            this.family_occupation = this.search_items[index].member.family_occupation
            this.family_employer = this.search_items[index].member.family_employer
            this.family_employer_address = this.search_items[index].member.family_employer_address
            this.family_monthly_income = this.search_items[index].member.family_monthly_income
            this.family_employment_status = this.search_items[index].member.family_employment_status
            this.family_employment_date = this.search_items[index].member.family_employment_date
            this.ci_id=this.search_items[index].member.ci_id
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info_co() {
        if (this.search_items_co.length > 0) {
          var index = this.search_items_co.map(function (x) {
            return x.id;
          }).indexOf(this.search_co.id)
          if (index != -1) {
            this.last_name_co = this.search_items_co[index].member.last_name
            this.first_name_co = this.search_items_co[index].member.first_name
            this.middle_name_co = this.search_items_co[index].member.middle_name
            this.permanent_address_co = this.search_items_co[index].member.permanent_address
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      save_loan_application() {
        this.saving = true
        this.alert = false
        var flag = false
        if (moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
          || moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
          flag = true
        } else {
          var compareDate = moment(this.date_accomplished, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_accomplished = ''
          this.alert = true
          this.alert_message = 'Date Accomplished is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          var proceed = true;
          if (this.croppieImage === '') {
            this.alert = true
            this.alert_message = 'Please Upload Valid ID'
            this.saving = false
            proceed = false
          }
          if (this.croppieImage_bus === '' && this.type_of_gadget === 'Business') {
            this.alert = true
            this.alert_message = 'Please Upload Business Permit'
            this.saving = false
            proceed = false
          }
          if (this.croppieImage_home === '') {
            this.alert = true
            this.alert_message = 'Please Upload Home Sketch / Place'
            this.saving = false
            proceed = false
          }
          if (proceed) {

            if (this.$refs.form.validate()) {
              const data = new FormData()
              data.append('member_id', this.search.id);
              data.append('old_member_id', this.loans_data.member_id);
              data.append('loans_data_id', this.loans_data.id);
              data.append('laf_no', this.laf_no);
              data.append('last_name', this.last_name.toUpperCase());
              data.append('first_name', this.middle_name.toUpperCase());
              data.append('middle_name', this.middle_name.toUpperCase());
              data.append('branch_id', this.branch_id);
              data.append('type_of_gadget', this.type_of_gadget);
              data.append('mode_of_loan', this.mode_of_loan);
              if (this.assumed_status === 'Reconstruct Loan') {
                var splited_terms_loans = this.terms.split(' ')
                data.append('terms', splited_terms_loans[0]);
                data.append('interest', this.interest);
              } else {
                data.append('terms', 0);
                data.append('interest', 0);
              }
              data.append('amount_due', this.amount_due);
              data.append('month_of', this.loans_data.month_of);

              data.append('valid_id_picture', this.croppieImage);
              data.append('business_permit', this.croppieImage_bus);
              data.append('home_skecth', this.croppieImage_home);

              data.append('date_accomplished', moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY'));
              data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
              data.append('place_of_birth', this.place_of_birth);
              data.append('height', this.height);
              data.append('weight', this.weight);
              data.append('tin_no', this.tin_no);
              data.append('sss_no', this.sss_no);
              data.append('specify_valid_id', this.type_of_id);
              data.append('valid_id_no', this.id_no);
              data.append('civil_status', this.civil_status);
              data.append('no_of_dependents', this.no_of_dependents);
              data.append('gender', this.gender);
              data.append('present_address', this.present_address.toUpperCase());
              data.append('religion', this.religion);
              data.append('residence', this.residence_is);
              data.append('residence_others', this.residence_specify);
              data.append('permanent_address', this.permanent_address.toUpperCase());
              data.append('citizenship', this.citizenship);
              data.append('contact_no', this.contact_no);
              data.append('email_address', this.email_address);
              data.append('occupation', this.occupation);
              data.append('type_of_gadget', this.type_of_gadget);
              data.append('employment_date', this.employment_date);
              data.append('employer', this.employer);
              data.append('employer_address', this.employer_address.toUpperCase());
              data.append('employer_contact_no', this.employer_contact_no);
              data.append('monthly_income', this.monthly_income);
              data.append('employment_status', this.employment_status);
              data.append('family_last_name', this.family_last_name.toUpperCase());
              data.append('family_first_name', this.family_first_name.toUpperCase());
              data.append('family_middle_name', this.family_middle_name.toUpperCase());
              data.append('family_date_of_birth', moment(this.family_date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'));
              data.append('family_contact_no', this.family_contact_no);
              data.append('family_occupation', this.family_occupation.toUpperCase());
              data.append('family_employer', this.family_employer.toUpperCase());
              data.append('family_employer_address', this.family_employer_address.toUpperCase());
              data.append('family_monthly_income', this.family_monthly_income);
              data.append('family_employment_status', this.family_employment_status);
              data.append('family_employment_date', this.family_employment_date);

              data.append('co_maker_id', this.search_co.id);
              data.append('ci_by_id', this.ci_id);
              var with_signature = 0
              if (this.is_with_signature) {
                with_signature = 1
              }
              data.append('is_with_signature', with_signature);
              this.register_new_gadget_loan_assumed(data)
                .then(response => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: response.data,
                  })
                  this.close_dialog()
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  console.log(error)
                  this.saving = false
                })
            } else {
              this.alert = true
              this.alert_message = 'Please Fill up the field/s'
              this.saving = false
            }
          }
        }
      }
    }
  }
</script>
