<template>
  <div>
    <v-card
      flat
      class="pt-4"
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONEY LOANS HISTORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="pt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Application #
            </th>
            <th class="text-center text-uppercase">
              Terms
            </th>
            <th class="text-center text-uppercase">
              Date
            </th>
            <th class="text-center text-uppercase">
              Amount
            </th>
            <th class="text-center text-uppercase">
              Interest
            </th>
            <th class="text-center text-uppercase">
              Loan Savings
            </th>
            <th class="text-center text-uppercase">
              Total
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase">
              SOA
            </th>
<!--            <th class="text-center text-uppercase">-->
<!--              Sched-->
<!--            </th>-->
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              For Assume
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              For Reconstruct
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              Change Category
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>{{ item.laf_no }}</td>
            <td class="text-center">
              {{ item.terms }}
            </td>
            <td class="text-center">
              {{ item.date_accomplished }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.amount) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.interest) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.loan_savings) }}
            </td>
            <td class="text-center">
              {{ formatPrice(item.total) }}
            </td>
            <td class="text-center">
              <div v-if="item.paid===3">
                <v-chip color="error" dark>RECONSTRUCTED</v-chip>
              </div>
              <div v-else-if="item.paid===2">
                <v-chip color="error" dark>ASSUMED</v-chip>
              </div>
              <div v-else-if="item.processing_fee===0">
                <v-chip color="error" dark>PENDING</v-chip>
              </div>
              <div v-else-if="item.paid === 1">
                <v-chip color="success" dark>PAID</v-chip>
              </div>
              <div v-else>
                <v-chip color="warning" dark>ACTIVE</v-chip>
              </div>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="soa_data(item)"
              >
                {{icons.mdiFileEye}}
              </v-icon>
            </td>
<!--            <td class="text-center">-->
<!--              <v-icon-->
<!--                class="mr-2"-->
<!--                color="warning"-->
<!--                @click="print_loan_schedule(item)"-->
<!--              >-->
<!--                {{icons.mdiAccountClock}}-->
<!--              </v-icon>-->
<!--            </td>-->
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="error"
                v-if="item.paid===0&&item.is_reconstructed===0 && position==='ADMIN'"
                @click="assume_loan(item)"
              >
                {{icons.mdiAccountCash}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="error"
                v-if="item.paid===0 &&item.is_reconstructed===0 && position==='ADMIN'"
                @click="recontruct_loan(item)"
              >
                {{icons.mdiCashPlus}}
              </v-icon>
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="warning"
                v-if="item.paid===0 &&item.is_reconstructed===0 && position==='ADMIN'"
                @click="change_cate(item)"
              >
                {{icons.mdiPencil}}
              </v-icon>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="soa_show" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LOANS STATEMENT OF ACCOUNT</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="pt-6"
            >
              <v-text-field label="Type of Loan"
                            v-model="loans_data.type_of_loan"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pt-6"
            >
              <v-text-field label="Specify"
                            v-model="loans_data.type_of_loan_others"
                            v-if="loans_data.type_of_loan === 'Other'"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Loan Amount"
                            v-model="loans_data.amount"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Mode of Loan"
                            v-model="loans_data.mode_of_loan"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Terms of Loan"
                            v-model="loans_data.terms"
                            outlined
                            dense
                            readonly
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Due Amount"
                            readonly
                            v-model="amount_due"
                            outlined
                            dense
                            background-color="info"
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Interest"
                            readonly
                            v-model="loans_data.interest"
                            outlined
                            dense
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Loan Savings"
                            readonly
                            v-model="loans_data.loan_savings"
                            outlined
                            dense
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Total"
                            readonly
                            v-model="total_loan"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Total Charges"
                            readonly
                            v-model="total_charges"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field label="Balance"
                            background-color="warning"
                            readonly
                            v-model="balance"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
              v-if="loans_data.is_assumed===1"
            >
              <v-text-field label="Remarks"
                            background-color="warning"
                            readonly
                            v-model="assume_by"
                            outlined
                            dense
                            style="font-size: xx-large"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LOANS PAYMENT</h4>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <h4 class="font-weight-light">Print SOA:</h4>
          <v-icon
            class="mr-2"
            @click="print_soa(0)"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          <v-spacer></v-spacer>
          <h4 class="font-weight-light">Print Principal SOA:</h4>
          <v-icon
            v-if="position==='ADMIN'"
            class="mr-2"
            @click="print_soa(1)"
            color="warning"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          <v-spacer></v-spacer>
          <h4 class="font-weight-light">Print SOA Summary:</h4>
          <v-icon
            v-if="position==='ADMIN'"
            class="mr-2"
            @click="print_soa(2)"
            color="info"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">
                Payment For
              </th>
              <th class="text-uppercase">
                Principal
              </th>
              <th class="text-uppercase">
                Interest
              </th>
              <th class="text-uppercase">
                Savings
              </th>
              <th class="text-uppercase">
                Premium
              </th>
              <th class="text-center text-uppercase">
                Over Due Charge
              </th>
              <th class="text-uppercase">
                OR Date
              </th>
              <th class="text-center text-uppercase">
                OR No
              </th>
              <th class="text-center text-uppercase">
                Paid Amount
              </th>
              <th class="text-center text-uppercase">
                Balance
              </th>
              <th class="text-center text-uppercase">
                Balance Principal
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in loans_payment"
              :key="item.id"
            >
              <td>{{ item.due_date }}</td>

              <td class="text-center">
                {{ formatPrice(item.principal) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.interest) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.savings) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.premium) }}
              </td>

              <td class="text-center">
                {{ formatPrice(item.over_due_charge) }}
              </td>
              <td>{{ item.or_date }}</td>
              <td class="text-center">
                {{ item.or_no }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.amount) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.balance) }}
              </td>
              <td class="text-center">
                {{ formatPrice(item.balance_principal) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view_dialog" persistent max-width="80%">
      <reconstruct-loan
        v-on:dialog_value="on_recontruct_loan_dialog_close"
        :value="this.loans_data"
        :member_info="this.member_data"
        :key="this.key"
        :prev_balance="prev_balance"
      ></reconstruct-loan>
      <v-btn color="error" @click="view_dialog = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="change_cat" persistent max-width="450px">
      <change-category-loan
        v-on:dialog_value="on_change_cate_dialog_close"
        :value="this.loans_data"
        :key="this.key"
        :prev_balance="prev_balance"
      ></change-category-loan>
      <v-btn color="error" @click="change_cat = false"> Close</v-btn>
    </v-dialog>
    <assume-loans-application
      :key="this.key"
      v-on:dialog_value="on_assume_dialog_close"
      :dialog_show="assumme_show"
      :loans_data="loans_data"
      :loans_information="loans_information"
      :prev_balance="prev_balance"
    ></assume-loans-application>
  </div>
</template>

<script>
  import {
    mdiPrinter, mdiFileEye, mdiAccountCash, mdiAccountClock
    , mdiCashPlus, mdiPencil
  } from '@mdi/js'
  import {mapGetters} from "vuex";
  import moment from 'moment'
  import AssumeLoansApplication from '../member_components/loans/AssumeLoansApplication'
  import ReconstructLoan from '../member_components/loans/ReconstructLoan'
  import ChangeCategoryLoan from '../member_components/loans/ChangeCategoryLoan'

  export default {
    props: {
      data: Array,
      member_data: Object,
    },
    components: {
      AssumeLoansApplication,
      ReconstructLoan,
      ChangeCategoryLoan
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/logos/main-logo.png'),
        icons: {
          mdiPrinter,
          mdiFileEye,
          mdiAccountCash,
          mdiAccountClock,
          mdiCashPlus,
          mdiPencil,

        },
      }
    },
    data() {
      return {
        key: 0,
        change_cat: false,
        view_dialog: false,
        assumme_show: false,
        soa_show: false,
        loans_data: {},
        loans_information: [],
        loans_payment: [],
        balance: 0,
        prev_balance: 0,

        total_loan: 0,
        total_charges: 0,
        amount_due: 0,

        assume_by: '',
        data_temp: {}
      }
    },
    computed: {
      ...mapGetters('authentication', ['branch_address', 'branch_contact_no', 'position']),
      ...mapGetters('system_data', ['company_logo']),
    },
    watch: {
      soa_show: function () {
        if (!this.soa_show) {
          this.loans_data = {}
          this.loans_payment = []
          this.data_temp = {}
          this.amount_due = 0
          this.total_charges = 0
          this.balance = 0
          this.assume_by = ''
        }
      }
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      soa_data(data) {
        this.soa_show = true
        this.loans_data = data
        this.loans_payment = data.loans_payment
        this.data_temp = data
        var bal = parseFloat(data.balance);
        this.assume_by = data.assume_by
        this.total_charges = this.formatPrice(data.over_due_charges);
        this.balance = this.formatPrice(bal);
        if (data.is_assumed === 1 && data.is_continue === 0) {
          this.balance = 0
        }
        this.total_loan = this.formatPrice(this.loans_data.total)
        this.amount_due = this.formatPrice(this.loans_data.amount_due)
      },
      print_soa(value) {
        var imgData = this.company_logo
        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Payment For', alignment: 'left', style: 'label'},
            {text: 'Principal', alignment: 'left', style: 'label'},
            {text: 'Interest', alignment: 'left', style: 'label'},
            {text: 'Savings', alignment: 'left', style: 'label'},
            {text: 'Premium', alignment: 'left', style: 'label'},
            {text: 'Over Due', alignment: 'left', style: 'label'},
            {text: 'Or Date', alignment: 'left', style: 'label'},
            {text: 'OR #', alignment: 'left', style: 'label'},
            {text: 'Paid Amount', alignment: 'left', style: 'label'},
            {text: 'Balance', alignment: 'left', style: 'label'},
          ]
        )
        var total_penalty = 0;
        var total_pri_col = 0;
        var total_int_col = 0;
        var total_save_col = 0;
        var total_over_col = 0;
        var total_col = 0;
        if (this.loans_payment.length > 0) {
          var bal = parseFloat(this.loans_data.total)
          this.loans_payment.forEach(function (item) {
            total_penalty += parseFloat(item.over_due_charge)
            var proceeds = true
            if (value === 2) {
              if (item.deposit_slip_id === null) {
                proceeds = false
              }
            }
            if (proceeds) {
              total_pri_col += parseFloat(item.principal)
              total_int_col += parseFloat(item.interest)
              total_save_col += parseFloat(item.savings)
              total_over_col += parseFloat(item.over_due_charge)
              total_col += parseFloat(item.amount)
              accounts_array.push(
                [
                  {text: item.due_date, alignment: 'left'},
                  {
                    text: (item.principal / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                  {
                    text: (item.interest / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                  {
                    text: (item.savings / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                  {
                    text: (item.premium / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                  {
                    text: (item.over_due_charge / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                  {text: item.or_date, alignment: 'left'},
                  {text: item.or_no.substring(0, item.or_no.indexOf('-')), alignment: 'left'},
                  {
                    text: (item.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                  {
                    text: ((value === 0 || value === 2 ? item.balance : item.balance_principal) / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    alignment: 'left'
                  },
                ]
              )
            }
            }
          )
          if (value === 2) {
            accounts_array.push(
              [
                {text: 'TOTAL', alignment: 'left'},
                {
                  text: (total_pri_col / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {
                  text: (total_int_col / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {
                  text: (total_save_col / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {
                  text: '',
                  alignment: 'left'
                },
                {
                  text: (total_over_col / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {text: '', alignment: 'left'},
                {text: '', alignment: 'left'},
                {
                  text: (total_col / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  alignment: 'left'
                },
                {
                  text: '',
                  alignment: 'left'
                },
              ]
            )
          }
        } else {
          accounts_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.data_temp.branch.address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.data_temp.branch.contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {
              text: 'LOAN STATEMENT OF ACCOUNT (' + (value === 2 ? 'PRINCIPAL BALANCE ' : (value === 3 ? 'SUMMARY ' : '')) + 'SOA)',
              style: 'title'
            },
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              columns: [
                {text: 'Branch: ' + this.data_temp.branch.branch_code, style: 'main_info'},
                {
                  text: 'Date Accomplished: ' + this.data_temp.date_accomplished,
                  style: 'main_info'
                },
                {text: 'LAF #: ' + this.data_temp.laf_no, style: 'main_info'},
              ]
            },
            '_________________________________________________________________________________________________',
            'MEMBERS PERSONAL INFORMATION',
            ' ',
            {
              columns: [
                {
                  text: 'Applicant Name: ' + this.member_data.last_name + ', ' + this.member_data.first_name + ' ' + this.member_data.middle_name,
                  style: 'main_info'
                },
                {
                  text: 'Contact #: ' + this.data_temp.loans_information[0].contact_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Present Address: ' + this.data_temp.loans_information[0].present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'CO MAKER INFORMATION',
            ' ',
            {
              columns: [
                {text: 'CO - MAKER NAME', style: 'main_info'},
                {text: 'CO - MAKER CONTACT #', style: 'main_info'},
                {text: 'CO - MAKER ADDRESS', style: 'main_info'},
              ]
            },
            {
              columns: [
                {
                  text: this.data_temp.loans_information[0].co_maker.last_name + ', ' + this.data_temp.loans_information[0].co_maker.first_name + ' ' + this.data_temp.loans_information[0].co_maker.middle_name,
                  style: 'main_info'
                },
                {text: this.data_temp.loans_information[0].co_maker.contact_no, style: 'main_info'},
                {
                  text: this.data_temp.loans_information[0].co_maker.present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'LOAN DATA',
            ' ',
            {
              columns: [
                {text: this.data_temp.mode_of_loan, style: 'main_info'},
                {text: this.data_temp.terms, style: 'main_info'},
                {text: '|', style: 'main_info'},
                {
                  text: (this.data_temp.amount / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' +',
                  style: 'main_info'
                },
                {
                  text: (this.data_temp.interest / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' +',
                  style: 'main_info'
                },
                {
                  text: (this.data_temp.loan_savings / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' +',
                  style: 'main_info'
                },
                {
                  text: (total_penalty / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' =',
                  style: 'main_info'
                },
                {
                  text: (parseFloat(this.data_temp.total) + parseFloat(total_penalty) / 1).toFixed(2).replace(',', '.').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {text: 'Mode', style: 'sub_info'},
                {text: 'Terms', style: 'sub_info'},
                {text: '|', style: 'main_info'},
                {text: 'Loan Amount', style: 'sub_info'},
                {text: 'Interest', style: 'sub_info'},
                {text: 'Loan Savings', style: 'sub_info'},
                {text: 'Penalty', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',

            {
              text: this.loans_data.is_assumed === 1 ? this.assume_by : '',
              alignment: 'left',
              style: 'main_info',
              color: 'blue'
            },
            {
              text: 'BALANCE: ' + this.balance,
              alignment: 'center',
              style: 'main_info',
              color: 'red'
            },
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [70, 40, 35, 35, 40, 35, 85, 35, 35, 50],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
      print_loan_schedule(data) {

        var accounts_array = []
        accounts_array.push(
          [
            {text: 'Due Date', alignment: 'left', style: 'label'},
            {text: 'Principal', alignment: 'left', style: 'label'},
            {text: 'Interest', alignment: 'left', style: 'label'},
            {text: 'Savings', alignment: 'left', style: 'label'},
            {text: 'Premium', alignment: 'left', style: 'label'},
            {text: 'Balance', alignment: 'left', style: 'label'}
          ]
        )
        var maximum_due = 0
        var partial_balance = data.total
        var loanAmount = data.amount
        var interestttt = data.interest
        var loan_savings = 0
        var partial_loan_due_amount = 0
        var due_date = data.date_accomplished
        var amount_due = data.amount_due

        accounts_array.push(
          [
            {text: due_date, alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(0), alignment: 'left'},
            {text: this.formatPrice(partial_balance), alignment: 'left'},
          ]
        )
        switch (data.mode_of_loan) {
          case "Weekly":
            maximum_due = parseFloat(data.terms) * 4
            loan_savings = parseFloat(data.loan_savings) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - (loan_savings + (interestttt / maximum_due)))
            for (var i = 1; i <= maximum_due; i++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(7, 'days').format('MMMM D, YYYY')
              partial_balance = partial_balance - amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(loanAmount / maximum_due), alignment: 'left'},
                  {text: this.formatPrice(interestttt / maximum_due), alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          case "15/30":
            maximum_due = parseFloat(data.terms) * 2
            loan_savings = parseFloat(data.loan_savings) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - (loan_savings + (interestttt / maximum_due)))
            for (var x = 1; x <= maximum_due; x++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(15, 'days').format('MMMM D, YYYY')
              partial_balance = partial_balance - amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(loanAmount / maximum_due), alignment: 'left'},
                  {text: this.formatPrice(interestttt / maximum_due), alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          case "Monthly":
            maximum_due = parseFloat(data.terms)
            loan_savings = parseFloat(data.loan_savings) / parseFloat(maximum_due)
            partial_loan_due_amount = (amount_due - (loan_savings + (interestttt / maximum_due)))
            for (var j = 1; j <= maximum_due; j++) {
              due_date = moment(due_date, 'MMMM D, YYYY').add(1, 'months').format('MMMM D, YYYY')
              partial_balance = partial_balance - amount_due
              accounts_array.push(
                [
                  {text: due_date, alignment: 'left'},
                  {text: this.formatPrice(loanAmount / maximum_due), alignment: 'left'},
                  {text: this.formatPrice(interestttt / maximum_due), alignment: 'left'},
                  {text: this.formatPrice(loan_savings), alignment: 'left'},
                  {text: this.formatPrice(amount_due), alignment: 'left'},
                  {text: this.formatPrice(partial_balance), alignment: 'left'},
                ]
              )
            }
            break;
          default:
            break;
        }

        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: this.company_logo, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: data.branch.address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + data.branch.contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LOAN SCHEDULE OF PAYMENT', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            ' ',
            {
              columns: [
                {text: 'Branch: ' + data.branch.branch_code, style: 'main_info'},
                {
                  text: 'Date Accomplished: ' + data.date_accomplished,
                  style: 'main_info'
                },
                {
                  text: 'LAF #: ' + data.laf_no,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'MEMBERS PERSONAL INFORMATION',
            ' ',
            {
              columns: [
                {
                  text: 'Applicant Name: ' + this.member_data.last_name + ', ' + this.member_data.first_name + ' ' + this.member_data.middle_name,
                  style: 'main_info'
                },
                {
                  text: 'Contact #: ' + data.loans_information[0].contact_no,
                  style: 'main_info'
                },
              ]
            },
            {
              columns: [
                {
                  text: 'Present Address: ' + data.loans_information[0].present_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'CO MAKER INFORMATION',
            ' ',
            {
              columns: [
                {text: 'CO - MAKER NAME', style: 'main_info'},
                {text: 'CO - MAKER CONTACT #', style: 'main_info'},
                {text: 'CO - MAKER ADDRESS', style: 'main_info'},
              ]
            },
            {
              columns: [
                {
                  text: data.loans_information[0].co_maker.last_name + ', ' + data.loans_information[0].co_maker.first_name,
                  style: 'main_info'
                },
                {
                  text: data.loans_information[0].co_maker.contact_no,
                  style: 'main_info'
                },
                {
                  text: data.loans_information[0].co_maker.permanent_address,
                  style: 'main_info'
                },
              ]
            },
            '_________________________________________________________________________________________________',
            'LOAN DATA',
            ' ',
            {
              columns: [
                {text: data.mode_of_loan, style: 'main_info'},
                {text: data.terms + ' month/s', style: 'main_info'},
                {text: '|', style: 'main_info'},
                {
                  text: this.formatPrice(data.amount) + '     +',
                  style: 'main_info'
                },
                {
                  text: this.formatPrice(data.interest) + '     +',
                  style: 'main_info'
                },
                {
                  text: this.formatPrice(data.loan_savings) + '     =',
                  style: 'main_info'
                },
                {text: this.formatPrice(data.total), style: 'main_info'},
              ]
            },
            {
              columns: [
                {text: 'Mode', style: 'sub_info'},
                {text: 'Terms', style: 'sub_info'},
                {text: '|', style: 'main_info'},
                {text: 'Loan Amount', style: 'sub_info'},
                {text: 'Interest', style: 'sub_info'},
                {text: 'Loan Savings', style: 'sub_info'},
                {text: 'Total', style: 'sub_info'},
              ]
            },
            '================================================================================',
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [80, 80, 80, 80, 80, 80, 80, 80, 80, 80],
                body: accounts_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();

        this.saving = false
      },
      assume_loan(data) {
        this.key++
        this.loans_payment = data.loans_payment
        var total_payment = 0;
        var total_penalty = 0
        if (this.loans_payment.length > 0) {
          this.loans_payment.forEach(function (item) {
              total_penalty += parseFloat(item.over_due_charge)
              total_payment += parseFloat(item.amount)
            }
          )
          this.prev_balance = (parseFloat(data.total) + parseFloat(total_penalty)) - parseFloat(total_payment);
        }
        this.loans_data = data
        this.loans_information = data.loans_information
        this.assumme_show = true
      },
      on_assume_dialog_close() {
        this.assumme_show = false
        this.loans_data = {}
        this.loans_information = []
        this.loans_payment = []
      },
      recontruct_loan(data) {
        this.key++
        this.loans_payment = data.loans_payment
        var total_payment = 0;
        var total_penalty = 0
        if (this.loans_payment.length > 0) {
          this.loans_payment.forEach(function (item) {
              total_penalty += parseFloat(item.over_due_charge)
              total_payment += parseFloat(item.amount)
            }
          )
          this.prev_balance = (parseFloat(data.total) + parseFloat(total_penalty)) - parseFloat(total_payment);
        }
        this.loans_data = data
        this.loans_information = data.loans_information
        this.view_dialog = true
      },
      on_recontruct_loan_dialog_close() {
        this.view_dialog = false
        this.loans_data = {}
        this.loans_information = []
        this.loans_payment = []
        this.$emit('reset_data', false)
      },
      change_cate(data) {
        this.key++
        this.loans_payment = data.loans_payment
        var total_payment = 0;
        var total_penalty = 0
        if (this.loans_payment.length > 0) {
          this.loans_payment.forEach(function (item) {
              total_penalty += parseFloat(item.over_due_charge)
              total_payment += parseFloat(item.amount)
            }
          )
          this.prev_balance = (parseFloat(data.total) + parseFloat(total_penalty)) - parseFloat(total_payment);
        }
        this.loans_data = data
        this.loans_information = data.loans_information
        this.change_cat = true
      },
      on_change_cate_dialog_close() {
        this.change_cat = false
        this.loans_data = {}
        this.loans_information = []
        this.loans_payment = []
      },
    },
  }
</script>
