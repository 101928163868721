<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary" v-if="Object.keys(this.value).length===0">
        <v-toolbar-title><h4 class="font-weight-light">CHANGE CATEGORY</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-card
              flat
              dense
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">Loan Data</h4></v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                    class="pt-6"
                  >
                    <v-select
                      :items="type_of_loan_items"
                      item-value="type"
                      item-text="type"
                      label="Type of Loan"
                      required
                      :rules="rules.combobox_rule"
                      v-model="type_of_loan"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pt-6"
                  >
                    <v-text-field label="Specify"
                                  :rules="rules.default_max_45_character_and_no_empty_rule"
                                  v-model="type_of_loan_others"
                                  v-if="type_of_loan === 'Other'"
                                  outlined
                                  dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"

              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                @click="save_loan_application"
                v-if="!saving"
              >
                Save changes
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      value: Object
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.loan_counter_data()
    },
    data() {
      return {
        loan_insurance: 0,
        loan_insurance_comm: 0,
        loan_insurance_addtnl: 0,
        loan_processing_fee: 0,
        mortuary_insurance: 250,
        share_capital: 0,
        foundation: 50,

        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,
        croppieImage_bus: '',
        cropped_bus: this.avatarImg,
        croppieImage_home: '',
        cropped_home: this.avatarImg,

        search: '',
        search_member: '',
        search_items: [],

        search_co: '',
        search_member_co: '',
        search_items_co: [],

        collectors_items: [],
        collector_id: 0,

        date_accomplished: '',
        laf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        height: '',
        weight: '',
        tin_no: '',
        sss_no: '',
        type_of_id: '',
        id_no: '',
        civil_status: '',
        no_of_dependents: '',
        gender: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        religion: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        family_employment_date: '',
        is_with_signature: true,

        purpose_of_loan: '',
        type_of_loan: '',
        type_of_loan_others: '',
        loan_amount: '',
        mode_of_loan: '',
        terms: '',
        due_amount: '',
        interest: '',
        interestt: 0,
        due_interest: 0,
        loan_savings: '',
        loan_savingss: '',
        total: '',
        totall: '',
        ci_items: [],
        ci_id: 0,
        amount_due: 0,
        due_loan_savings: '',

        last_name_co: '',
        first_name_co: '',
        middle_name_co: '',
        permanent_address_co: '',
        type_of_loan_items: [],
      }
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member_co: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member_co == '') {
            this.search_items_co = []
          } else {
            this.searching_search_items_co(this.search_member_co)
          }
        }, 500)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id', 'user_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members_paid', 'search_members']),
      ...mapActions('loans_data', ['loan_initialize_data', 'is_member_have_unpaid_loans', 'register_new_loan', 'update_category']),
      ...mapActions('loans_data', ['type_of_loan_list']),

      loan_counter_data() {
        this.type_of_loan_list()
          .then(response => {
            this.type_of_loan_items = response.data
          })
        if (Object.keys(this.value).length > 0) {
          this.last_name = this.value.members.last_name
          this.first_name = this.value.members.first_name
          this.middle_name = this.value.members.middle_name
          this.type_of_loan = this.value.type_of_loan
          this.loan_amount = this.value.amount
          this.mode_of_loan = this.value.mode_of_loan
          this.terms = this.value.terms + ' month/s'
          this.purpose_of_loan = this.value.purpose_of_loan
          this.laf_no = this.value.laf_no
          this.date_accomplished = moment(this.value.date_accomplished, 'MMMM D, YYYY').format('YYYY-MM-DD')
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      save_loan_application() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          if (Object.keys(this.value).length > 0) {
            const data = new FormData()
            data.append('loan_app_id', this.value.id);
            data.append('type_of_loan', this.type_of_loan);
            this.update_category(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'warning',
                  text: response.data,
                })
                this.$emit('dialog_value', false)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                console.log(error)
                this.saving = false
              })
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
